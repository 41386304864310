/* This file is called when the LCA results page shows */
import GenericChartHelper from "../../modules/charts/genericChartHelper";

const RENDERED_CHARTS = {}

export default function () {
    setupCharts()
}

function setupCharts() {
    const activeTabPane = $('.tab-pane.active')
    renderActiveCharts(activeTabPane)

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        const targetDivId = $(e.target).attr('aria-controls')
        const targetDiv = $(`#${targetDivId}`)
        renderActiveCharts(targetDiv)
    })
}

function renderActiveCharts(targetDiv) {
    const ctx = $(targetDiv).find('canvas[id*=-gchart]')
    ctx.each(function (index, element) {
        if(!RENDERED_CHARTS[element.id]) {
            const chartHelper = new GenericChartHelper($(element))
            chartHelper.renderChart()
            RENDERED_CHARTS[element.id] = true
        }
    })
}
