<template>
  <div class="form p-2 mb-3 shadow-sm bg-white rounded">
    <h3 class="scoped-form-title py-1 text-primary">{{ props.title }}</h3>
    <!--
    <div v-if="isFormValid" class="text-success">I am valid!</div>
    <div v-else class="text-danger">Not valid</div>
    -->
    <div
      v-for="(field, fieldIndex) in formFields"
      :key="fieldIndex"
      class="form-group m-0 mb-3"
      :class="{ 'd-none': !dependenciesActive(field) }"
    >
      <div v-for="r in repeatTimes(field)" :key="r" class="row">
        <div v-if="!isHidden(field.type)" class="col-4 mb-3">
          <label :for="field.id"
            >{{ dynamicLabels[getVModelId(field)] }}
            <span v-if="field.repeat">[{{ r }}]</span
            ><span v-if="!field.optional">*</span></label
          >
        </div>

        <div class="col-7">
          <input
            v-if="field.type === 'hidden'"
            type="hidden"
            class="form-control"
            :id="fullInputName(field)"
            :name="isModelAttribute(field) ? fullInputName(field) : null"
            v-model="formData[getVModelId(field)]"
          />

          <input
            v-if="field.type === 'integer'"
            type="number"
            class="form-control"
            :id="fullInputName(field, r)"
            :name="fullInputName(field, r)"
            v-model="formData[getVModelId(field, r)]"
            :placeholder="field.description"
            :min="field.range.min"
            :max="field.range.max"
            required
          />

          <input
            v-if="field.type === 'real'"
            type="number"
            class="form-control"
            :id="fullInputName(field, r)"
            :name="fullInputName(field, r)"
            v-model="formData[getVModelId(field, r)]"
            :placeholder="field.description"
            :min="field.range.min"
            :max="field.range.max"
            step="any"
            required
          />

          <input
            v-if="field.type === 'string'"
            class="form-control"
            :type="field.type"
            :id="fullInputName(field, r)"
            :name="fullInputName(field, r)"
            v-model="formData[getVModelId(field, r)]"
            :placeholder="field.description"
            required
          />

          <textarea
            v-if="field.type === 'text'"
            class="form-control"
            :id="fullInputName(field, r)"
            :name="fullInputName(field, r)"
            v-model="formData[getVModelId(field, r)]"
            :placeholder="field.description"
          ></textarea>

          <input
            v-if="field.type === 'file'"
            type="file"
            class="form-control"
            :id="fullInputName(field, r, '_')"
            :name="fullInputName(field, r)"
          />

          <select
            @change="errorsList = []"
            v-if="field.type === 'multipleChoice'"
            class="form-control"
            :id="fullInputName(field, r)"
            :name="isModelAttribute(field, r) ? fullInputName(field, r) : null"
            v-model="formData[getVModelId(field, r)]"
            required
          >
            <option value="" disabled="disabled" selected="selected">
              Select an option
            </option>
            <option
              v-for="(option, optIndex) in field.options"
              :key="optIndex"
              :value="option['value']"
              :data-filter="option.filter"
            >
              {{ option["text"] }}
            </option>
          </select>

          <div v-if="field.type === 'radio'" :id="fullInputName(field)">
            <label v-for="(option, optIndex) in field.options" :key="optIndex">
              <input
                type="radio"
                :value="option"
                v-model="formData[getVModelId(field)]"
                :name="fullInputName(field)"
                required
              />
              {{ option }}
            </label>
          </div>

          <input
            v-if="field.type === 'date'"
            class="form-control"
            :type="field.type"
            :id="fullInputName(field, r)"
            :name="fullInputName(field, r)"
            v-model="formData[getVModelId(field, r)]"
            :placeholder="field.description"
            required
          />

          <span
            v-if="!isHidden(field.type) && field.range"
            class="range-column ml-1 mr-2"
          >
            <span v-if="field.type === 'integer' || field.type === 'real'">
              (<span>Min: {{ field.range.min }}</span
              ><span v-if="field.range.max">- Max: {{ field.range.max }}</span
              >)
            </span>
          </span>

          <div
            v-if="
              field.type === 'checkboxes' &&
              formData[getVModelId(field)] !== null
            "
          >
            <label v-for="(option, index) in field.options" :key="index">
              <input type="checkbox" :value="true" v-model="formData[getVModelId(field)][option.value]" :id="`${fullInputName(field,r)}[${option.value}]`" :name="`${fullInputName(field,r)}[${option.value}]`"
                     @click="emitEvent(option)">

              {{ option.text }}
            </label>
          </div>

          <span v-if="hasInvalidValue(field, r)" class="text-danger small"
            >Invalid value</span
          >

          <div v-if="hasErrors(getVModelId(field, r))" class="error-column">
            <small class="text-danger">{{
              dbObject.errors[getVModelId(field, r)].join(", ")
            }}</small>
          </div>
        </div>

        <div v-if="!isHidden(field.type)" class="tooltips-column">
          <span
            :key="fieldIndex"
            class="tooltips"
            :data-instructions="field.instructions"
            style="margin-left: 5px"
          >
            <i
              class="fa fa-question-circle-o"
              style="font-size: 20px"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      </div>
    </div>
    <!--    <ul>-->
    <!--      <li class="text-danger" v-for="(error, index) in errorsList" :key="index" >-->
    <!--          {{ error }}-->
    <!--      </li>-->
    <!--    </ul>-->
    <slot></slot>
  </div>
  <!-- {{ formData }} -->
</template>

<script setup>
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";

const emit = defineEmits(["valid"]);

const props = defineProps({
  title: String,
  formFields: {
    type: Array,
    required: true,
  },
  prefix: {
    type: String,
    required: false,
    default: "",
  },
  dbObject: {
    type: Object,
    required: true,
  },
});

onBeforeMount(() => {
  initializeCheckboxValues()
})

onMounted(() => {
})

const formFields = props.formFields;
const dbObject = props.dbObject;
const formData = ref(dbObject);
fillMissingAttributesToFormData();
fillDefaultValues();

function fillDefaultValues() {
  for(let k in formData.value) {
    const value = formData.value[k]
    const formField = _getFormFieldById(k)
    if(value === null && formField && formField.default !== undefined) {
      formData.value[k] = formField.default
    }
  }
}

function initializeCheckboxValues() {
  const checkboxFields = formFields.filter(e => e.type === 'checkboxes')
  for(let i in checkboxFields) {
    const field = checkboxFields[i]
    if(!formData.value[getVModelId(field)]) {
      formData.value[getVModelId(field)] = {}
      field.options.forEach(option => {
        formData.value[getVModelId(field)][option.value] = false
      })
    }
  }
}

function fillMissingAttributesToFormData() {
  // Some attributes in the forms.yml are not included in the model of Rails.
  // For example Insulation does not have a field `type`. So, its value is not passed into form data when we
  // pass an existing database object. But some other fields might depend on that missing field, so we add it
  // in form data with this function.
  formFields.forEach((formField) => {
    if (!formData.value.hasOwnProperty(formField.id)) {
      formData.value[formField.id] = null;
    }
  });
}

function isModelAttribute(field) {
  if (field.hasOwnProperty("model")) return field.model;
  return true;
}

function fullInputName(field, index = 1, separator = "[]") {
  // TODO: I saw that Rails uses ids in the format of "model_attribute" when we use "model[attribute]".
  //  This is fine for all of the inputs except the file input. I want to change it for all inputs, but I don't
  //  remember if any methods rely on the id (even though I think it does not). So I am creating a special case for
  //  files right now. But in the future we should consider changing every id.
  const attribute = field.id;
  let nested = field.nested;
  if (nested && !Array.isArray(nested)) {
    nested = [nested];
  }
  let result = "";
  if (props.prefix.length === 0) {
    result = attribute;
  } else {
    if (separator === "[]") result = `${props.prefix}[${attribute}]`;
    else if (separator === "_") result = `${props.prefix}_${attribute}`;
  }

  if (nested) {
    if (field.repeat) {
      nested = nestedWithRepeatIndex(nested, index);
    }

    if (separator === "[]") {
      return `${result}[${nested.join("][")}]`;
    } else if (separator === "_") {
      return `${result}_${nested.join("_")}`;
    }
  } else return result;
}

function getVModelId(field, index = 1) {
  const fieldId = field.id;
  let nested = field.nested;
  if (nested && !Array.isArray(nested)) {
    nested = [nested];
  }

  if (nested) {
    if (field.repeat) {
      nested = nestedWithRepeatIndex(nested, index);
    }
    return `${fieldId}_${nested.join("_")}`;
  }
  return fieldId;
}

function nestedWithRepeatIndex(nested, repeatIndex) {
  let position = nested.length - 1;
  nested.splice(position, 0, repeatIndex);
  return nested;
}

const dynamicLabels = computed(() => {
  const labelsObject = {};
  for (let i in formFields) {
    const formField = formFields[i];
    labelsObject[getVModelId(formField)] = inputLabel(formField.label);
  }
  return labelsObject;
});

function inputLabel(label) {
  let finalLabel = label;
  if (typeof label === "object") {
    const finalLabel = label.default;
    for (let i in label.dependent) {
      const item = label.dependent[i];
      if (dependenciesActive(label)) {
        return item.text;
      }
    }
    return finalLabel;
  }
  return finalLabel;
}

const isFormValid = computed(() => {
  for (const field of formFields) {
    if (field.id === "id" || field.id === "comments") continue;
    const fType = field.type;
    const fValue = formData.value[field.id];
    const fRange = field.range;
    const activeDependencies = dependenciesActive(field);
    if (activeDependencies) {
      if (fValue === null) {
        return false;
      }
      if (
        (fType === "integer" || fType === "real") &&
        (formData.value[field.id] < fRange.min ||
          formData.value[field.id] > fRange.max)
      ) {
        return false; // Form is invalid
      }
    }
  }
  return true; // Form is valid
});

const errorsList = ref([]);

// Watch for changes in formData and trigger validation
watch(
  formData,
  (newValue, oldValue) => {
    validateFormFields(newValue);
  },
  { deep: true }
);

// Function to validate form fields
function validateFormFields(formData) {
  for (const field of formFields) {
    if (["baseline", "id", "building_id", "comments"].includes(field.id))
      continue;
    const fType = field.type;
    const fValue = formData[field.id];
    const fRange = field.range;
    const activeDependencies = dependenciesActive(field);
    if (activeDependencies) {
      if (fValue === null) {
        const errorMessage = `${
          field.label.default ? field.label.default : field.label
        } is required but is empty.`;
        if (!errorsList.value.includes(errorMessage)) {
          errorsList.value.push(errorMessage);
        }
        // Handle validation error for this field
      } else {
        if (field.label) {
          removeError(
            `${
              field.label.default ? field.label.default : field.label
            } is required but is empty.`
          );
        }
      }
      if (
        (fType === "integer" || fType === "real") &&
        (fValue < fRange.min || fValue > fRange.max)
      ) {
        const errorMessage = `${
          field.label.default ? field.label.default : field.label
        } has an invalid value.`;
        if (!errorsList.value.includes(errorMessage)) {
          errorsList.value.push(errorMessage);
        }
        // Handle validation error for this field
      } else {
        if (field.label) {
          removeError(
            `${
              field.label.default ? field.label.default : field.label
            } has an invalid value.`
          );
        }
      }
    }
  }
}

// Function to remove error from errorsList
function removeError(errorMessage) {
  const index = errorsList.value.indexOf(errorMessage);
  if (index !== -1) {
    errorsList.value.splice(index, 1);
  }
}

function hasInvalidValue(field, r) {
  const formDataItem = formData.value[getVModelId(field, r)];
  const integerOrReal = ["integer", "real"].includes(field.type);
  const isNotEmpty = formDataItem !== "" && formDataItem !== null;
  const range = field.range;
  let exceedsMin = false;
  let exceedsMax = false;
  if (range) {
    exceedsMin = range.min !== undefined ? formDataItem < range.min : false;
    exceedsMax = range.max !== undefined ? formDataItem > range.max : false;
  }
  const exceedsRange = exceedsMin || exceedsMax;
  return integerOrReal && isNotEmpty && exceedsRange;
}

// Watchers
// Auto values currently work for only one dependency.
const updatesValueFields = formFields
  .filter((e) => "updates" in e)
  .map((e) => ({ id: e.id, updates: e.updates }));
// https://stackoverflow.com/questions/62729380/vue-watch-outputs-same-oldvalue-and-newvalue
const computedFormData = computed(() => {
  return Object.assign({}, formData.value);
});

watch(
  computedFormData,
  (newValue, oldValue) => {
    for (let i in updatesValueFields) {
      // parse all fields that update other fields
      const updateField = updatesValueFields[i]; // store the current field in a constant
      if (
        newValue[getVModelId(updateField)] !==
        oldValue[getVModelId(updateField)]
      ) {
        // if the field has changed value
        const inputType = _getFormFieldType(updateField.id); // get the type of the field that updates because we should
        // handle different types independently, e.g. for multipleChoice we have to find the text value.
        const dependentFields = updateField.updates; // find the dependent fields of the current field, i.e. those
        // that should be updated
        if (inputType === "multipleChoice") {
          // handle different types here
          const textValue = _getValueOfSelectedField(
            updateField.id,
            newValue[updateField.id]
          );
          const updateFieldFilterValue = _getfilterValueOfSelectField(
            updateField.id,
            newValue[updateField.id]
          );
          _updateDependentFields(
            dependentFields,
            textValue,
            updateFieldFilterValue
          );
        }
      }
    }
  },
  { deep: true }
);

// Helpers for above method
function _getFormFieldById(fieldId) {
  if (typeof fieldId === "object") {
    fieldId = fieldId.id;
  }
  let dField = null;
  formFields.forEach((f) => {
    if (getVModelId(f) === fieldId) {
      dField = f;
    }
  });
  return dField; // formFields.find(e => getVModelId(e) === fieldId) // find its type
}

function _getFormFieldType(fieldId) {
  return _getFormFieldById(fieldId).type;
}

function _getOptionOfSelectField(fieldId, value) {
  const formField = formFields.find((e) => getVModelId(e) === fieldId); // find its type
  return formField.options.find((e) => e.value === value);
}

function _getTextValueOfSelectField(fieldId, value) {
  // this repeats the same search as _getFormFieldType(fieldId) but I want the code to be clear
  return _getOptionOfSelectField(fieldId, value).text;
}

function _getValueOfSelectedField(fieldId, value) {
  return _getOptionOfSelectField(fieldId, value).value;
}

function _getfilterValueOfSelectField(fieldId, value) {
  if (_getOptionOfSelectField(fieldId, value).filter !== undefined) {
    return _getOptionOfSelectField(fieldId, value).filter;
  } else return "";
}

function _updateDependentFields(
  dependentFieldsWithRules,
  fieldValue,
  updateFieldFilterValue
) {
  // dependentFieldsWithRules: array of fields to be updated and their rules
  // fieldValue: the value based on which the dependent fields will be updated
  for (let i in dependentFieldsWithRules) {
    const item = dependentFieldsWithRules[i];
    const dependentField = item;
    const rules = item.rules;
    _updateDependentField(
      dependentField,
      rules,
      fieldValue,
      updateFieldFilterValue
    );
  }
}

function _updateDependentField(
  dependentField,
  rules,
  value,
  updateFieldFilterValue
) {
  // dependentField: a field id
  // rules: the list of rules from YAML
  // value: the value based on which the field will change
  // find which rule is activated:
  let fieldId = dependentField.id;
  for (let i in rules) {
    const rule = rules[i];
    for (let j in rule.includes) {
      const str = rule.includes[j];
      const lowerCaseUpdateFieldFilterValue =
        updateFieldFilterValue !== undefined
          ? updateFieldFilterValue.toLowerCase()
          : "";

      if (
        value.toString().toLowerCase().includes(str.toLowerCase()) ||
        lowerCaseUpdateFieldFilterValue.includes(str.toLowerCase())
      ) {
        formData.value[dependentField.id] = rule.value;
        //iterate through form data if field includes str then set its value to rule.value
        for (const key in formData.value) {
          if (Object.prototype.hasOwnProperty.call(formData.value, key)) {
            if (key.includes(fieldId)) {
              // || selectedValue.inludes(fieldId)
              formData.value[key] = rule.value;
            }
          }
        }

        _lockInput(dependentField, rule.lock === true); // I added === true to handle cases when undefined.
      }
    }
  }
}

function _lockInput(field, lock = true) {
  const inputField = document.getElementById(fullInputName(field));
  if (inputField) {
    inputField.disabled = lock;
  }
}

function clearInput(field) {
  const inputField = document.getElementById(fullInputName(field));
  inputField.value = null;
}

function repeatTimes(field) {
  const repeat = field.repeat;
  if (repeat) {
    const sourceId = field.repeat.source;
    const sourceField = _getFormFieldById(sourceId);
    const times = formData.value[getVModelId(sourceField)];
    if (times > 0) {
      return parseInt(times);
    } else {
      return 0;
    }
  }
  return 1;
}

// ----------------------------
watch(isFormValid, (newValue) => {
  emit("valid", newValue);
});

watch(
  formData,
  (newValue, oldValue) => {
    validateFormFields(newValue);
  },
  { deep: true }
);

function isHidden(fieldType) {
  return fieldType === "hidden";
}

function hasErrors(attribute) {
  return (
    dbObject.errors &&
    dbObject.errors[attribute] &&
    dbObject.errors[attribute].length > 0
  );
}

function dependenciesActive(field) {
  const dependsOn = field.dependent;
  let active = [];
  if (dependsOn) {
    for (let d in dependsOn) {
      let included = false;
      const fieldId = getVModelId(dependsOn[d]);
      const options = dependsOn[d].options;
      if (formData.value[fieldId]) {
        // because in some cases during initialization some attributes might be missing from formData
        const formFieldType = _getFormFieldType(fieldId);
        if (formFieldType === "multipleChoice") {
          const dependsOnFieldValue = formData.value[fieldId]
            .toString()
            .toLowerCase();
          const dependsOnFieldText = _getTextValueOfSelectField(
            fieldId,
            formData.value[fieldId]
          ).toLowerCase();
          const dependsOnFieldFilter = _getfilterValueOfSelectField(
            fieldId,
            formData.value[fieldId]
          ).toLowerCase();
          options.forEach((o) => {
            const lowercaseOption = o.toLowerCase();
            included =
              included ||
              dependsOnFieldValue.includes(lowercaseOption) ||
              dependsOnFieldFilter.includes(lowercaseOption);
          });
        } else if (formFieldType === "checkboxes") {
          included =
            included || options.every((e) => formData.value[fieldId][e]);
        }
      }
      active.push(included);
    }
    return active.every((e) => e);
  }
  return true;
}

function emitEvent(option) {
  if (option.emits) {
    emit(option.emits);
  }
}
</script>

<style scoped>
.scoped-form-title {
  border-bottom: 2px solid #313e5b;
  font-size: 18px;
  color: #007bff;
}

label {
  color: darkslategray;
  font-size: 14px;
  font-weight: unset;
}
</style>
