// This is a generic file that initializes a Vue app when given the element to be mounted
// and the root component
import { createApp } from 'vue'
import TestComponent from "../vue/TestComponent.vue";

export default function(elementId, rootComponent) {
    const mountElement = document.getElementById(elementId)
    if(mountElement) {
        const props = JSON.parse(mountElement.dataset.vue)
        mountElement.removeAttribute('data-vue')
        const app = createApp(rootComponent, props)
        app.mount(mountElement)
    }
}