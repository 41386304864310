<template>
  <div class="row">
    <div v-for="(dbObject, i) in formObjects" :key="generateUniqueKeyForObject(dbObject)" class="col-lg-6">
      <AutomaticForm
          :db-object="dbObject" :form-fields="formFields" :prefix="generateUniquePrefixForObject(dbObject)"
          :title= "props.title"  @valid="onValid($event, i)">

          <div class="d-flex justify-content-end w-100 top-right">
            <button type="button" class="btn btn-warning " @click="duplicateFormContainer(i)" v-if="!hasReachedMax()">
                  <i class="fa fa-clone" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn btn-warning ml-2" @click="removeFormContainer(i)" v-if="!hasReachedMax()">
                  <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
          </div>
      </AutomaticForm>
    </div>
  </div>
<!-- <p v-if="errors.length !=0" class="text-danger">There Are Errors That need to be corrected before you continue</p> -->
  <button type="button" class="btn-sm rounded-pill btn-primary" @click="addFormContainer()" v-if="!hasReachedMax()"> <!-- :disabled="errors.length > 0"-->
    <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Component
  </button>

  <div class="components-bin">
    <div v-for="(dbObject, i) in removedObjects" :key="i">
      <input type="hidden" :name="`${generateUniquePrefixForObject(dbObject)}[id]`" :value="dbObject.id">
      <input type="hidden" :name="`${generateUniquePrefixForObject(dbObject)}[_destroy]`" :value="true">
    </div>
  </div>
  <!-- {{ formObjects }} -->
</template>

<script setup>
  import {computed, onMounted, ref} from 'vue'
  import AutomaticForm from "./AutomaticForm.vue";

  const emit = defineEmits(['valid'])

  const errors = ref([])

  const props = defineProps({
    formFields: {
      type: Array,
      required: true
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    dbObjects: {
      type: Array,
      required: true
    },
    title:{
      type: String,
      required: false
    },
    maxNestedForms: {
      type: Number,
      required: false,
      default: 100
    },
    startWith: { // If we want the form to start with some existing containers.
      type: Number,
      required: false,
      default: 0
    },
    associations: {
      type: Object,
      required: false, 
    },
  })

  const formFields = props.formFields
  const formObjects = ref(props.dbObjects.map(o => Object.assign({ valid: false }, o)))

  const removedObjects = ref([]) // We need this for Rails. If the form contains a db object and we want to remove
  // that object, we have to add two hidden inputs: id: dbObject.id and _destroy: true so that Rails automatically
  // removes the associated element from the scenario.
  const nestedIsValid = ref(formObjects.value.map(_ => false)) // holds reference to the child forms to check if all are valid

   const isFormValid = computed(() => {

    if(formObjects.value.length === 0) {
      return true;
    }
    return formObjects.value.every(obj => obj);
  });

  onMounted(() => {
    for(let o in formObjects.value) {
      const object = formObjects.value[o]
      object.formId = generateUniqueKeyForObject(object)
    }
    if(props.startWith !== 0 && formObjects.value.length === 0) {
      for(let i = 0; i < props.startWith; i++) {
        addFormContainer()
      }
    }
  })

  function addFormContainer() {
    formObjects.value.push(createNewFormContainer())
    nestedIsValid.value.push(false)
  }

  function createNewFormContainer() {
    let newFormObject = {}

    for (let i = 0; i<formFields.length; i++){
      let key = formFields[i]
      newFormObject[key.id] = null
    }

    newFormObject["errors"] = {}
    newFormObject.formId = generateUniqueKeyForObject(newFormObject)
    return newFormObject
  }

  function removeFormContainer(index) {
    const removedObject = formObjects.value[index]
    formObjects.value.splice(index, 1)
    nestedIsValid.value.splice(index, 1)
    if(removedObject.id) {
      removedObjects.value.push(removedObject)
    }
  }

  function duplicateFormContainer(index) {
    let duplicateObject = JSON.parse(JSON.stringify(formObjects.value[index]));
    duplicateObject.id = null
    duplicateObject.formId = generateUniqueKeyForObject(duplicateObject)
    formObjects.value.push(duplicateObject)
    nestedIsValid.value.push(false)
  }

  function generateUniqueKeyForObject(dbObject) {
    // Use the database id if it exists. Otherwise add the current timestamp (I consider this a same method since the
    // user most probably will not click the "Add another component" button at the exact same time.
    let uniqueId = Date.now() + Math.floor(Math.random() * 500) + 1
    if(dbObject.id) {
      uniqueId = dbObject.id
    }
    return uniqueId
  }

  function generateUniquePrefixForObject(dbObject) {
    // Because in Rails we need to send components with a unique id. The name of the input must:
    // scenario[hvac_attributes][...][name] where the three dots must be a unit identifier (irrelevant to the database).
    if(props.prefix.length === 0) return props.prefix

    const uniqueId = dbObject.formId // generateUniqueKeyForObject(dbObject)
    return `${props.prefix}[${uniqueId}]`
  }

  function onValid(e, index) {
    if(e === false){
        errors.value.push(e)
      }
    else{
      errors.value.pop()
    }
    // nestedIsValid.value[index] = e
  }

  function isSingleForm() {
    return props.maxNestedForms === 1
  }

  function hasReachedMax() {
    return formObjects.value.length === props.maxNestedForms
  }

</script>

<style scoped>
  .top-right {
    position: absolute;
    top: 3px;
    right: 18px;
  }
</style>