import accessRulesForm from "../access_rules/access_rules_form";
import createVueApp from "../../utils/create_vue_app";
import ScenarioForm from "../../vue/scenarios/ScenarioForm.vue";
import DataSourceForm from "../../vue/data_sources/DataSourceForm.vue";
import valid_for_lca  from "../../helpers/scenario_validation";

let currentPath, page;

export default function (path) {
  currentPath = path;
  setPage();
  loadScript();
}

function setPage() {
  if (currentPath.includes("/access_rules")) {
    page = "access_rule_form";
    // if (currentPath.includes('/new')) page = 'new_access_rule'
    // if (currentPath.includes('/edit')) page = 'edit_access_rule'
    // else page ="access_rule"
  } else if ($("#scenario-form").length === 1) {
    page = "scenarios_form";
  } else if ($("#data-source-form").length === 1) {
    page = "data_source_form";
  } else if (currentPath.includes("/lca_analysis")) {
    page = "lca_analysis";
  } else if (currentPath.match(/\/buildings\/[0-9]+$/)) {
    page = "valid_for_lca";
  }
}

function loadScript() {
  if (page === "access_rule_form") {
    accessRulesForm();
  } else if (page === "scenarios_form") {
    window.history.replaceState({}, "", window.location.href.split("?")[0]);
    createVueApp("vue-app", ScenarioForm);
    // Enable disabled inputs before sending to server. E.g. `purpose`.
    const submitButton = $(":submit");
    $(submitButton).on("click", (e) => {
      e.preventDefault();
      const disabledInputs = $("input:disabled, select:disabled, option:disabled");
      $(disabledInputs).each(function () {
        $(this).removeAttr("disabled");
      });

      // decide continue param
      $("input#continue").val($(e.target).data().continue);
      $("form").submit();
    });
  } else if (page === "data_source_form") {
    createVueApp("vue-app", DataSourceForm);
  } else if (page === "lca_analysis") {
    var currentUrl = window.location.href;
    var updatedUrl = currentUrl.replace(/&?commit=Submit/, "");
    if (updatedUrl !== currentUrl) {
      history.replaceState({}, "", updatedUrl);
    }
  }
  else if (page === "valid_for_lca"){
    valid_for_lca() //From Scenario_validatiion.js File
  }
}
