// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//import "styles/application.scss"
//require("@rails/ujs").start()
import 'bootstrap';
//import "packs/styles/application.scss";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import autoHide from '../pages/auto_hide';

// import testPage from '../pages/home/test_page.js';

import slideShow from '../modules/slideShow';
import maps from '../pages/maps/index';
import initializeNotifications from '../pages/notifications/index';
import notificationSubscription from '../pages/notifications/userSubscription';
import loadBuildingScripts from '../pages/building/javascript_loader'

import userDashboard from '../pages/users/dashboard'
import 'chartjs-plugin-colorschemes';
import lcaResults from "../pages/lca/results";

require("@rails/ujs").start()
require('turbolinks').start()
require("@rails/activestorage").start()
require("@rails/actioncable")

require("channels")
console.log('##In application.js !');
require("chartkick")
require("chart.js")

// From https://rubyyagi.com/how-to-use-bootstrap-and-jquery-in-rails-6-with-webpacker/
var jQuery = require('jquery')
require("cocoon")
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "./sol";

document.addEventListener('turbolinks:load', () => {

  const pathname = window.location.pathname
  loadBuildingScripts(pathname)

  // if (window.location.pathname == "/test_page") testPage();
  if (pathname.includes('graphs')) maps();
  else if (pathname.includes('notifications')) initializeNotifications();
  else if (pathname === '/dashboard') {
    userDashboard()
  }
  // Stupid code to reset the user dashboard selected menu item.
  // Check if its under /buildings/:id or /client/client-name/buildings/:id
  const underBuildings = pathname.match(/\/buildings\/\d+/)
  const underClient = pathname.match(/\/client\/(([a-z]+)-)+[a-z]+\/building\/\d+/)
  if(pathname !== '/dashboard' && !underBuildings && !underClient) {
    localStorage.removeItem('tagSelected')
  }

  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  $('.my-select, #baseline-select, #comparison-select').searchableOptionList(
      {
        useBracketParameters: true,
      }
  );

  $(".hBack").on("click", function(e) {
    e.preventDefault();
    window.history.back();
  });

  $('.file-download').on('click', function(e) {
    e.preventDefault()
    window.open($(this).data('href'))
    window.focus()
  })

  $(".spinner-on-click").on("click", function() {
    $(this).html(
      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
    );
    $(this).trigger("blur");
  });

  autoHide();
  slideShow();

  notificationSubscription();

  lcaResults()

})
